import React from "react"
// import { Link } from "gatsby"

import Layout from "../layout"
import Header from "../Header/Header"
import HeroImage from "../HeroImage/HeroImage"

import "./home.scss"

const Home = () => (
  <section className="home">
    <Header />
    <div className="container">
      <div className="home__hero">
        <div className="home__hero-image-container">
          <div className="home__hero-image-wrapper">
            <HeroImage />
          </div>
        </div>
        <div className="home__hero-text-container">
          {/* <h1 className="home__hero-title">Lisette Cervano</h1> */}
          {/* <h3 className="home__hero-subtitle">Administrative Professional</h3> */}
          <p className="home__hero-body">
            I am an executive assistant and administrator with over twenty years
            of experience. As a certified “Jane of all trades”, I apply my wide
            skillset to the mission of leaving every workplace better than when
            I found it. I am currently Executive Assistant to the CEO at
            HoneyBook, previously at Yelp!, Quid, and YouNoodle.
          </p>
          <p className="home__hero-body">
            I am fluent in Spanish, a dog mom, and an Aries. The way to my heart
            is through Blue Bottle New Orleans Iced Coffees with oat milk.
          </p>
          <div className="home__hero-list">
            <strong>Awards</strong>
            <ul>
              <li>
                (2017) The Colleen Barrett Award for Administrative Excellence
              </li>
            </ul>
          </div>
          <div className="home__hero-list">
            <strong>Publications</strong>
            <ul>
              <li>
                (2018) Meet the Executive Assistant Whose Career Path Will
                Inspire Your Own
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Home
