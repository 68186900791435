import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import "./header.scss"

const Header = ({ siteTitle }) => (
  <header className="header">
    <div className="header__container container">
      <h1 className="header__title">
        {/* {siteTitle} */}
        Lisette Cervano
      </h1>
      <h3 className="header__subtitle">Executive Assistant</h3>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
